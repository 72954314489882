
import HolidaysModel from "../../model/holidays.model";
import ApiServices from "../../services/api.service";
import "./holidays.scss";
import React, { useState } from 'react';
import Moment from "react-moment";
import ADD_BUTTON_IMG from "../../assets/images/add-button.png"
import AddHolidaysModal from "../modal/add-holiday";
import ProfileModel from "../../model/profile.model";
import { getAuth } from "firebase/auth";
import config from "../../config";
import tokenService from "../../services/token.service";
import moment from "moment";
import JSON_HOLIDAY from "../../assets/json/holiday-2024.json";
const HolidaysComponent = ({ profileRes }: any) => {
    const profile: ProfileModel = profileRes
    const user = tokenService.getUser();
    const [holidayList, setHolidayList] = useState<HolidaysModel[]>();
    const [showAddHoliday, setShowAddHoliday] = useState(false);
    const loadHolidays = () => {

        ApiServices().getHolidays().then((res) => {
            const querySnapshot = res;
            let hdList: HolidaysModel[] = [];
            querySnapshot.forEach((doc) => {
                const dataRes = doc.data() as HolidaysModel;
                // addHolidaytoCalendar(dataRes);
                hdList.push(dataRes);
                
            });
            console.log(hdList);
         
            setHolidayList(hdList);

        })
    }

    const addHolidaytoCalendar = async(item: HolidaysModel) => {
        const auth = getAuth();
        if (auth) {

            try {
                const eventNow = {
                    'summary': "วันหยุด : " + item.title,
                    'description':
                        "<b>รายละเอียด</b>" + "<ul><li>เนื่องในวัน : " + item.title + " </li>" + "</ul>",
                    'start': {
                        'date': moment(item.date.toDate()).format("YYYY-MM-DD"),
                        'timeZone': 'Asia/Bangkok',
                    },
                    'end': {
                        'date': moment(item.date.toDate()).add(1,'days').format("YYYY-MM-DD"),
                        'timeZone': 'Asia/Bangkok',
                    }

                }

                await fetch(config.api.google_apis + config.calendarOfficeHoliday + "/events", {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                    body: JSON.stringify(eventNow)
                }).then((data) => {
                    return data.json();
                }).then((data) => {
                    console.log(data);
                   
                })
               
            } catch {
               

            }



        } else {
        }
    }

    const addHolidays = () => {
        // setShowAddHoliday(true);

    }

    React.useEffect(() => {
        console.log(profile);
        console.log("1111 : ");
        loadHolidays();
    }, [])
    return (
        <>
            <div className="container holidays">
                <div className="d-flex holidays-header-control">
                    <span className="holidays-text">All Holidays ({holidayList?.length})</span>
                    {
                        profile && profile.email == "benz@scoutout.net" ?
                        <img src={ADD_BUTTON_IMG} className="add-holiday-button" onClick={addHolidays}></img> : null
                    }

                </div>


                <div className="holidays-list">
                    <span className="holidays-title">No.</span>
                    <span className="holidays-title">Title.</span>
                    <span className="holidays-title">Date</span>
                </div>

                <div className="divider-line"></div>

                {
                    holidayList?.map((item: HolidaysModel, index: number) => {

                        return (
                            <div className="d-flex flex-column holidays-item" key={index}>
                                <div className="holidays-list" >
                                    <span className="holidays-desc">{index + 1}</span>
                                    <span className="holidays-desc">{item.title}</span>
                                    <span className="holidays-desc">  <Moment format="DD-MMM-YYYY">
                                        {item.date.toDate()}
                                    </Moment></span>
                                </div>
                                <div className="divider-line"></div>
                            </div>
                        )
                    })
                }


                {

                    showAddHoliday && <AddHolidaysModal isShow={true} ></AddHolidaysModal>
                }
            </div>
        </>
    )
}

export default HolidaysComponent;