import { initializeApp } from 'firebase/app';
import { getAuth, getRedirectResult, GoogleAuthProvider, onAuthStateChanged, signInWithCredential, signInWithCustomToken, signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { collection, getDocs, getFirestore, orderBy, query, Timestamp, where } from 'firebase/firestore';
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import ApiCalendar from 'react-google-calendar-api';
import "react-datepicker/dist/react-datepicker.css";
import config from '../../config';
import HolidaysModel from '../../model/holidays.model';
import ProfileModel from '../../model/profile.model';
import Form from 'react-bootstrap/Form';
import "./request-leave.scss";
import LeaveModel from '../../model/leave.model';
import ApiServices from '../../services/api.service';
import configLanguage from '../../config-language';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import tokenService from '../../services/token.service';
import moment from 'moment';
const RequestLeaveComponent = (props: any) => {
    const clientId = config.clientId;
    const { setValue, other, handleClose, isNextYear } = props;
    const profileRes: ProfileModel = props.other;
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const user = tokenService.getUser();
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date("12/31/" + config.currentYear));
    const [isSelectEndDate, setIsSelectEndDate] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState<Date>();
    const [excludeDate, setExcludeDate] = useState<HolidaysModel[]>();
    const [excDate, setExcDate] = useState<Date[]>();
    const [holidayError, setHolidayError] = useState<HolidaysModel[]>();
    const [totalLeave, setTotalLeave] = useState(0);
    const [typeLeave, setTypeLeave] = useState(99);
    const [leaveReason, setLeaveReason] = useState("");
    const [timeLeave, setTimeLeave] = useState(99);
    const [showError, setShowError] = useState(false);
    const [errorLeaveZero, setErrorLeaveZero] = useState(false);
    const [canLeave, setCanLeave] = useState(false);
    const apiCalendar = new ApiCalendar(config.calendarConfig);
    let navigate = useNavigate();
    const onChange = (dates: any) => {
        const [start, end] = dates;
        console.log(end);
        if (end == null) {
            setIsSelectEndDate(false);
        } else {
            setIsSelectEndDate(true)
        }
        const datesHolidays: HolidaysModel[] = [];
        setHolidayError(datesHolidays);
        setTimeLeave(99);
        setTypeLeave(99);
        setStartDate(start);
        setEndDate(end);
        getDatesInRange(start, end);
    };

    React.useEffect(() => {

        getHolidays();
    }, [])

    React.useEffect(() => {
        console.log(profileRes.remainPersonalLeave);
        if (isNextYear) {
            setMinDate(new Date("01/01/" + config.nextYear))
            setMaxDate(new Date("12/31/" + config.nextYear))
        } else {
            setMinDate(new Date())
            setMaxDate(new Date("12/31/" + config.currentYear))
        }
    }, [isNextYear])

    const getHolidays = async () => {

        const q = query(collection(db, "holidays"), orderBy("date", "asc"));

        const querySnapshot = await getDocs(q);
        const listDate: HolidaysModel[] = [];
        const listDateT: Date[] = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const docData = doc.data() as HolidaysModel;
            listDate.push(docData)
            listDateT.push(docData.date.toDate())
        });
        setExcDate(listDateT)
        setExcludeDate(listDate);

    }
    const getDatesInRange = (startDate: any, endDate: any) => {
        const date = new Date(startDate.getTime());

        const dates: Date[] = [];

        while (date <= endDate) {
            if (date.getDay() !== 6 && date.getDay() !== 0) {
                dates.push(new Date(date));
            }
            date.setDate(date.getDate() + 1);
        }

        if (excludeDate) {
            const datesHolidays: HolidaysModel[] = [];
            excludeDate?.map((item: HolidaysModel, index: number) => {

                dates.map((item2: Date, index2: number) => {
                    if (item2.toDateString() !== item.date.toDate().toDateString()) {
                        // dates.push(new Date(date));
                    } else {
                        dates.splice(index2, 1);
                        datesHolidays.push(item);
                    }
                })
            })
            if (datesHolidays.length > 0) {
                setHolidayError(datesHolidays);
            }
        } else {
            // dates.push(new Date(date));
        }

        if (dates.length >= 1) {
            setTimeLeave(0);
        }
        setTotalLeave(dates.length);
        isLeave(dates.length, typeLeave)
    }

    const leaveTypeChange = (event: any) => {
        setCanLeave(true);
        setTypeLeave(event.target.value);
        isLeave(totalLeave, event.target.value);
    }

    const leaveReasonChange = (event: any) => {
        setLeaveReason(event.target.value);
    }

    const leaveTimeChange = (event: any) => {
        setTimeLeave(event.target.value);
        if (event.target.value > 0) {
            if (totalLeave <= 1) {
                setTotalLeave(0.5);
                isLeave(0.5, typeLeave)
            }


        } else {
            setTotalLeave(1);
            isLeave(1, typeLeave)
        }
    }

    const isLeave = (totalLeave: number, typeLeave: number) => {

        if (typeLeave !== 99) {
            if (typeLeave == 0) {
                if (totalLeave > profileRes.remainPersonalLeave) {
                    setCanLeave(false);
                    setErrorLeaveZero(true);
                } else {
                    setErrorLeaveZero(false);
                    setCanLeave(true);
                }
            } else {
                if (totalLeave > profileRes.remainSickLeave) {
                    setCanLeave(false);
                    setErrorLeaveZero(true);
                } else {
                    setErrorLeaveZero(false);
                    setCanLeave(true);
                }
            }
        }



    }
    const isWeekday = (date: any) => {
        const day = date.getDay(date);
        return day !== 0 && day !== 6;
    };

    const submitLeave = () => {

        setErrorLeaveZero(false);
        if (!isSubmit) {

            if (startDate && endDate) {


                if (canLeave) {

                    setIsSubmit(true);
                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);

                    const leaveSubmit: LeaveModel = {

                        datecreate: Timestamp.now(),
                        dateleavefrom: Timestamp.fromDate(startDate),
                        dateleaveto: Timestamp.fromDate(endDate),
                        des: leaveReason,
                        useruid: profileRes.uid,
                        employeename: profileRes.displayName,
                        employeenickname: profileRes.employeenickname,
                        statusleave: "Awaiting",
                        leavetype: typeLeave,
                        leavetime: timeLeave,
                        leavedaytotal: totalLeave
                    }
                    if (isNextYear) {
                        leaveSubmit.leaveNextYear = totalLeave
                        leaveSubmit.isLock = true;

                    } else {
                        leaveSubmit.leavedaytotal = totalLeave
                        leaveSubmit.isLock = false;
                    }
                    let typeLeaveStr = "";
                    let timeLeaveStr = "";
                    let calendarId = "";
                    console.log("Leave type: " + typeLeave)
                    console.log("timeLeaveStr: " + timeLeave)
                    if (typeLeave == 0) {
                        typeLeaveStr = configLanguage.personal_leave
                        leaveSubmit.leavetype = configLanguage.personal_leave;
                        if (timeLeave == 0) {
                            timeLeaveStr = "เต็มวัน"
                            leaveSubmit.leavetime = configLanguage.full_day;
                            calendarId = config.calendarEmployeeleaveId
                        } else if (timeLeave == 1) {
                            timeLeaveStr = "ครึ่งวัน (เช้า)"
                            leaveSubmit.leavetime = configLanguage.halfday_am;
                            calendarId = config.calendarHalfleaveId
                        } else {
                            timeLeaveStr = "ครึ่งวัน (บ่าย)"
                            leaveSubmit.leavetime = configLanguage.halfday_pm;
                            calendarId = config.calendarHalfleaveId
                        }
                        console.log("Leave type string: " + typeLeaveStr)
                        console.log("timeLeaveStr type string: " + timeLeaveStr)

                    } else {

                        typeLeaveStr = configLanguage.sick_leave;
                        leaveSubmit.leavetype = configLanguage.sick_leave;
                        leaveSubmit.statusleave = configLanguage.approve;
                        leaveSubmit.approveDateCreate = Timestamp.now();
                        if (profileRes.remainSickLeave !== 0) {
                            leaveSubmit.leaveOverStatus = false;
                        } else {
                            leaveSubmit.leaveOverStatus = false;
                        }
                        console.log(timeLeave);
                        if (timeLeave == 0) {
                            timeLeaveStr = "เต็มวัน";
                            calendarId = config.calendarSickleaveId;
                            leaveSubmit.leavetime = configLanguage.full_day;
                        } else if (timeLeave == 1) {
                            timeLeaveStr = "ครึ่งวัน (เช้า)";
                            calendarId = config.calendarSickleaveId;
                            leaveSubmit.leavetime = configLanguage.halfday_am;
                            console.log(leaveSubmit);
                        } else {
                            timeLeaveStr = "ครึ่งวัน (บ่าย)";
                            calendarId = config.calendarSickleaveId;
                            leaveSubmit.leavetime = configLanguage.halfday_pm;
                        }
                    }



                    const eventNow = {
                        'summary': profileRes.displayName + ' ' + typeLeaveStr,
                        'description':
                            "<b>รายละเอียด</b>" + "<ul><li>เวลาที่ลา : " + timeLeaveStr + " </li>" + "<li>เหตุผลในการลา : " + leaveReason + "</li></ul>",
                        'start': {
                            'date': moment(startDate).format("YYYY-MM-DD"),
                            'timeZone': 'Asia/Bangkok',
                        },
                        'end': {
                            'date': moment(endDate).add(1, 'days').format("YYYY-MM-DD"),
                            'timeZone': 'Asia/Bangkok',
                        }

                    }

                    console.log(leaveSubmit);
                    addEvent2(calendarId, eventNow, leaveSubmit);
                } else {
                    alert("วันลาไม่พอ")
                    setErrorLeaveZero(true);
                }
            } else {
                alert("กรุณาเลือกวันที่ให้ครบ")
            }
        }
    }
    const addEvent2 = async (calendarID: any, event: any, leaveSubmit: any) => {
        const auth = getAuth();
        if (auth) {

            try {


                await fetch(config.api.google_apis + calendarID + "/events", {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                    body: JSON.stringify(event)
                }).then((data) => {
                    return data.json();
                }).then((data) => {
                    if (data.id) {
                        leaveSubmit.eventId = data.id;
                        ApiServices().saveLeave(leaveSubmit).then((res: any) => {
                            handleClose();
                            window.open(data.htmlLink)
                        })
                    } else {
                        leaveSubmit.eventId = "null";
                        ApiServices().saveLeave(leaveSubmit).then((res: any) => {
                            handleClose();
                        })
                    }
                })


            } catch {
                leaveSubmit.eventId = "null";
                ApiServices().saveLeave(leaveSubmit).then((res: any) => {
                    handleClose();
                })

            }



        } else {
            // navigate('/login')
        }

    };

    return (

        <>
            <div className="d-flex flex-column reqest-leave">

                <div className="calendar-control mx-auto">
                    <DatePicker
                        minDate={minDate}
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        filterDate={isWeekday}
                        excludeDates={excDate}
                        maxDate={maxDate}
                        selectsRange
                        inline
                    />
                </div>

                <div className="info-control">
                    <span className="title-text my-auto">วันลาเริ่มต้น</span>
                    <span className="value-text ml-auto my-auto">{startDate.toDateString()}</span>
                </div>

                <div className="info-control">
                    <span className="title-text my-auto">วันลาสิ้นสุด</span>

                    {
                        !isSelectEndDate ?

                            <span className='error-info-text ml-auto my-auto'>กรุณาเลือกวันสิ้นสุด</span> : <span className="value-text ml-auto my-auto">{
                                endDate &&
                                endDate.toDateString()

                            }</span>
                    }

                </div>

                <div className="info-control">
                    <span className="title-text my-auto">วันลาทั้งหมด</span>
                    <span className="value-text ml-auto my-auto">{

                        totalLeave
                    } วัน</span>
                </div>
                {
                    holidayError ?

                        holidayError.length > 0 ?

                            <div>
                                <div className="info-control ">
                                    <span className="title-text my-auto">ตรงกับวันหยุดราชการ</span>
                                    <span className="value-text ml-auto my-auto">{

                                        holidayError.length
                                    } วัน</span>
                                </div>

                            </div>
                            : null
                        : null
                }
                {
                    totalLeave <= 1 &&
                    <div className="info-control">
                        <span className="title-text my-auto">เลือกช่วงเวลา</span>
                        <Form.Select aria-label="Default select example" value={timeLeave} className='form-select' onChange={leaveTimeChange}>
                            <option value="99">เลือกช่วงเวลา</option>
                            <option value="0">เต็มวัน</option>
                            <option value="1">ครึ่งวัน (เช้า)</option>
                            <option value="2">ครึ่งวัน (บ่าย)</option>
                        </Form.Select>
                    </div>

                }

                <div className="info-control">
                    <span className="title-text my-auto">เลือกประเภทลา</span>
                    <Form.Select aria-label="Default select example" value={typeLeave} className='form-select' onChange={leaveTypeChange}>
                        <option value="99">เลือกประเภทลา</option>
                        <option value="0">พักร้อน</option>
                        {
                            !isNextYear && <option value="1">ป่วย</option>
                        }

                    </Form.Select>
                </div>
                <div className="info-control">
                    <span className="title-text my-auto">วันลาที่เหลือของคุณ </span>
                    <span className={
                        typeLeave == 0 ?
                            profileRes.remainPersonalLeave < 5 ? "error-info-text ml-auto my-auto" : "value-text ml-auto my-auto" :
                            profileRes.remainSickLeave < 5 ? "error-info-text ml-auto my-auto" : "value-text ml-auto my-auto"
                    }>
                        {
                            typeLeave == 0 ? "(พักร้อน)" : isNextYear ? " (พักร้อนจากปี " + config.currentYear + ")" : "(ป่วย)"
                        }
                        <br></br>
                        {
                            !isNextYear &&
                                typeLeave == 0 ?
                                profileRes.remainPersonalLeave : !isNextYear && typeLeave == 1 && profileRes.remainSickLeave
                        }
                        {
                            isNextYear && profileRes.remainPersonalLeave > 5 ?
                                5 + " จาก " + profileRes.remainPersonalLeave + " " : isNextYear && profileRes.remainPersonalLeave
                        }
                        วัน 

                    </span>
                </div>

                <div className="info-control d-flex flex-column text-left">
                    <span className="title-text my-auto text-left">เหตุผลในการลา</span>
                    <Form.Group className="mb-3" >
                        <Form.Control as="textarea" className='form-text-area' onChange={leaveReasonChange} rows={3} />
                    </Form.Group>
                    {
                        isNextYear && <span>ระบบจะทำการ ล็อควันลาโดยยังไม่หักจนกว่าจะปี {config.nextYear}</span>
                    }
                </div>


                {
                    errorLeaveZero &&
                    <div className="info-control">
                        <span className="error-info-text my-auto text-left">จำนวนวันลาของคุณไม่พอ ก้มหน้าทำงานต่อไป</span>
                    </div>
                }
                <button className={
                    canLeave && !isSubmit ? 'button-request-leave' :
                        isSubmit ? 'button-request-leave button-disabled' :
                            'button-request-leave button-disabled'
                } onClick={submitLeave}>{
                        isSubmit ? 'Submitting...' : 'ยืนยัน'}
                </button>



            </div >


        </>
    )
}

export default RequestLeaveComponent;