import ProfileModel from "../../../model/profile.model"
import "./profile.scss"
import "../../modal/confirm-mbti.scss";
import VACATION_IMG from "../../../assets/images/vacation.png";
import SICK_IMG from "../../../assets/images/sick.png";
import CALENDAR_IMG from "../../../assets/images/calendar.png";
import LeaveModel from "../../../model/leave.model";
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import RequestLeaveComponent from "../../request-leave/request-leave";
import configLanguage from "../../../config-language";
import { collection, doc, DocumentData, endAt, endBefore, getDoc, getDocs, limit, orderBy, query, QueryDocumentSnapshot, QuerySnapshot, startAfter, startAt, where } from "firebase/firestore";
import EXPAND_IMG from "../../../assets/images/expand.png";
import ADMIN_IMG from "../../../assets/images/admin-dashboard.png";
import COLLAPSE from "../../../assets/images/collapse.png";
import PROFILE_IMG from "../../../assets/images/emp.png";
import EmployeesDetailComponent from "../../admin/admin-employees/employees-detail/employees-detail";
import { getAuth, GoogleAuthProvider, signOut } from "firebase/auth";
import ApiServices from "../../../services/api.service";
import config from "../../../config";
import PersonalityModel from "../../../model/personality.model";
import SETTINGS_IMG from "../../../assets/images/settings.png";
import LOGOUT_IMG from "../../../assets/images/logout.png";
import TextResult from "../../../model/personality.model";
import MbtiResult from "../../../model/personality.model";
import { Radar } from "react-chartjs-2";
import Moment from "react-moment";
import { Tab, Tabs } from "react-bootstrap";
import DashboardComponent from "../dashboard";
import AnnouncementComponent from "../announcement/announcement";
import HolidaysComponent from "../../holidays/holidays";
import MBTIDetailComponent from "../mbti-detail/mbti-detail";
import { useNavigate, useSearchParams } from "react-router-dom";
import PersonalityResultModel from "../../../model/personality-results-model";
import SimilarPersonalityModel from "../../../model/similar-personality.model";
import SimilarPersonalityModal from "../../modal/similar-personality-popup";
import { initializeApp } from "firebase/app";
import tokenService from "../../../services/token.service";
import axios from "axios";
export const ProfileComponent = ({ dbAccess, profile, uid }: any) => {
    const [key, setKey] = useState('upcoming');
    let navigate = useNavigate();
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const PAGE_LIMIT = 5;
    const db = dbAccess;
    const [profileRes, setProfileRes] = useState<ProfileModel>();
    const user = tokenService.getUser();
    const startOfToday = new Date();
    startOfToday.setDate(startOfToday.getDate() - 1)
    const [show, setShow] = useState(false);
    const [showLeaveDetail, setShowLeaveDetail] = useState(false);
    const [showAllHoliday, setShowAllHoliday] = useState(false);
    const [showMBTIDetail, setShowMBTIDetail] = useState(false);
    const [showConfirmMbti, setShowConfirmMbti] = useState(false);
    const [showSimilarPersonality, setShowSimilarPersonality] = useState(false);
    const [isNextYear, setIsNextYear] = useState(false);

    const handleCloseLeaveDetail = () => setShowLeaveDetail(false);
    const handleCloseAllHolidays = () => setShowAllHoliday(false);
    const handleCloseMBTIDetail = () => setShowMBTIDetail(false);
    const handleCloseConfirmMbti = () => setShowConfirmMbti(false);
    const handleCloseSimilarPersonality = () => setShowSimilarPersonality(false);
    const [value, setValue] = useState(false);
    const [usePsnLeave, setUsePsnLeave] = useState(0);
    const [useRemainLastPsnLeave, setUseRemainLastPsnLeave] = useState(0);
    const [remainPsnLeave, setRemainPsnLeave] = useState(0);
    const [useSickLeave, setUseSickLeave] = useState(0);
    const [remainSickLeave, setRemainSickLeave] = useState(0);
    const [meLeaveHistory, setMeLeaveHistory] = React.useState<LeaveModel[]>();
    const [meLeaveHistoryPagination, setMeLeaveHistoryPagination] = React.useState<LeaveModel[]>();
    const [meLeavePagination, setMeLeavePagination] = React.useState<LeaveModel[]>();
    const [meLeaveCancelPagination, setMeLeaveCancelPagination] = React.useState<LeaveModel[]>();
    const [meLeave, setMeLeave] = React.useState<LeaveModel[]>();
    const [meLeaveCancel, setMeLeaveCancel] = React.useState<LeaveModel[]>();
    const [startAt, setStartAt] = React.useState(0);
    const [endAt, setEndAt] = React.useState(PAGE_LIMIT);
    const [startAtUpcoming, setStartAtUpcoming] = React.useState(0);
    const [endAtUpcoming, setEndAtUpcoming] = React.useState(PAGE_LIMIT);
    const [startAtCancel, setStartAtCancel] = React.useState(0);
    const [endAtCancel, setEndAtCancel] = React.useState(PAGE_LIMIT);
    const [personality, setPersonality] = React.useState<PersonalityModel>();
    const [personalityGraph, setPersonalityGraph] = React.useState<TextResult[]>();
    const [personalityResult, setPersonalitResult] = React.useState<MbtiResult>();
    const [allHolidays, setAllHolidays] = React.useState(0);
    const [listEmp, setListEmp] = useState<ProfileModel[]>();
    const [similarMBTI, setSimilarMBTI] = useState<ProfileModel[]>();
    const [data, setData] = React.useState(Object);
    const [searchParams, setSearchParams] = useSearchParams();
    const [updateMBTI, setUpdateMBTI] = useState<PersonalityResultModel>();
    const [answerID, setAnswerID] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [useOnlyDefaultLeave, setUseOnlyDefaultLeave] = useState(0);
    const options = {
        elements: {
            line: {
                borderWidth: 3,
                fill: true
            }
        },
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            step: {
                display: false,
            },
            legend: {
                display: false,
            },
            decimation: {
                enabled: false
            },

            tooltip: {
                display: false,
                family: "LINESeedSansTH_Rg",
                bodyFont: {
                    family: "LINESeedSansTH_Rg"
                    // This more specific font property overrides the global property

                },
            }

        },

        scales: {
            r: {
                angleLines: {
                    display: true,
                    borderWidth: 3
                },
                display: true,
                stacked: true,
                spanGaps: null,
                suggested: null,
                fontColor: "white",
                alignToPixels: true,
                grid: {
                    display: true,
                    borderWidth: 0.5
                },
                ticks: {
                    display: false
                }
            }
        }
    }
    const getUsersData = async (uid: any) => {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const dataRes = docSnap.data() as ProfileModel;
            setProfileRes(dataRes);
            console.log(dataRes);
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

    }

    useEffect(() => {
        if (profileRes) {
            if(!isLoaded){
                checkAuth();
                if (searchParams && searchParams.get("answer_id")) {
                    setAnswerID(searchParams.get("answer_id")!)
                    ApiServices().postAnswerID(searchParams.get("answer_id")).then((res: any) => {
                        setPersonality(res.results.mbti_results.type);
                        const updateMbti: PersonalityResultModel = {
                            mbti_results: res.results.mbti_results,
                            text_results: res.results.text_results
                        }
                        setUpdateMBTI(updateMbti);
                        setShowConfirmMbti(true);
                    })
                }
                getAllProfile()
                checkLeave(uid, profileRes)
                getMeLeaveHistory(uid);
                getMeLeave(uid);
                getPersonality(profileRes.mbti_id);
                getAllHolidays();
            }
           
        } else {
            getUsersData(uid)
        }
    }, [profileRes])
    const checkAuth = () => {
        // Make a GET request to the Calendar API
        try {
            axios.get(config.api.google_apis + config.calendarEmployeeleaveId, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                },
            })
                .then((response) => {
                    // If the request is successful, the token is valid
                    console.log(response);
                })
                .catch((error) => {
                    // If there's an error, the token is likely invalid
                    signOut(auth);
                });
        } catch {
            signOut(auth);
        }

    }

    const handleClose = () => {

        setShow(false);
        getMeLeave(uid)
        getMeLeaveHistory(uid);
    }
    const clickRequestLeave = (isNextYear) => {
        setShow(true)
        setIsNextYear(isNextYear)
    }
    const clickViewAdmin = () => {
        window.open('/admin', '_blank');
    }
    const clickAllHolidays = () => {
        setShowAllHoliday(true);
    }
    const clickGraphMBTI = () => {
        setShowMBTIDetail(true);
    }
    const getAllHolidays = async () => {
        const q = query(collection(db, "holidays"), where("date", ">", new Date("01/01/" + config.currentYear)),
            where("date", "<", new Date("01/01/" + config.nextYear)));
        const querySnapshot = await getDocs(q);
        console.log(querySnapshot.size);
        setAllHolidays(querySnapshot.size);
    }


    const checkLeave = async (id: any, profile: ProfileModel) => {
        const q = query(collection(db, "leave"), where("dateleavefrom", ">=", new Date("01/01/" + config.currentYear)),
            where("dateleavefrom", "<=", new Date("12/31/" + config.currentYear)),
            where("statusleave", "==", "Approved")
            , where("useruid", "==", id));
        const querySnapshot = await getDocs(q);
        const leaveme: LeaveModel[] = [];
        let personalLeave = 0;
        let useRemainLastYear = 0;
        let sickleave = 0;
        let usePersonalleave = 0;
        let useOnlyDFLeave = 0;
        if (querySnapshot.size === 0) {
            personalLeave = 0;
            setUsePsnLeave(0);
            const updateProfile = {
                usePersonalLeaveTotal: 0,
                remainPersonalLeave: profile.totalPersonalLeave + profile.lastYearRemainDay
            }
            setRemainPsnLeave(personalLeave);
            console.log("remain 2 : " + updateProfile);
            ApiServices().updateSyncLeave(uid, updateProfile);
        }
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const dataRes = doc.data() as LeaveModel;
            dataRes.docId = doc.id;
            if (dataRes.leavetype === configLanguage.personal_leave) {

                if (new Date(dataRes.datecreate.toDate()) < new Date("05/01/" + config.currentYear)) {

                    if (useRemainLastYear >= profile.lastYearRemainDay) {
                        useRemainLastYear = profile.lastYearRemainDay;
                    } else {
                        useRemainLastYear = useRemainLastYear + dataRes.leavedaytotal
                    }
                    personalLeave = personalLeave + dataRes.leavedaytotal;
                } else if (new Date(dataRes.datecreate.toDate()) > new Date("05/01/" + config.currentYear)) {
                    personalLeave = personalLeave + dataRes.leavedaytotal;
                }
                console.log("USE REMAIN LAST : " + useRemainLastYear)
                usePersonalleave += dataRes.leavedaytotal;
                console.log("USE PSN LEAVE" + usePersonalleave + " : " + personalLeave + " : " + dataRes.leavedaytotal);
            } else {
                sickleave += dataRes.leavedaytotal;
            }
           
            leaveme.push(dataRes);

        });
        const remainSickLeave = profile.totalSickLeave - sickleave;
        if (personalLeave > 0) {

            // if (useRemainLastYear >= profile.lastYearRemainDay) {
            //     const remainLeave = (profile.totalPersonalLeave + profile.lastYearRemainDay) - (personalLeave);
            //     setUsePsnLeave(personalLeave - useRemainLastYear);
            //     setRemainPsnLeave(remainLeave);
            //     console.log("remain 1 : " + remainLeave);
            //     const updateProfile = {
            //         remainPersonalLeave: remainLeave,
            //         usePersonalLeaveTotal: usePersonalleave
            //     }

            //     ApiServices().updateSyncLeave(uid, updateProfile);
            // } else {
            //     const remainLeave = (profile.totalPersonalLeave + profile.lastYearRemainDay) - (personalLeave + useRemainLastYear);
            //     setUsePsnLeave(personalLeave);
            //     setRemainPsnLeave(remainLeave);
            //     console.log("remain 2 : " + remainLeave);
            //     const updateProfile = {
            //         remainPersonalLeave: remainLeave,
            //         usePersonalLeaveTotal: usePersonalleave
            //     }
            //     console.log(updateProfile);
            //     ApiServices().updateSyncLeave(uid, updateProfile);
            // }

            const remainLeave = (profile.totalPersonalLeave + profile.lastYearRemainDay) - (personalLeave);
            setUsePsnLeave(personalLeave - useRemainLastYear);
            setRemainPsnLeave(remainLeave);
            console.log("remain 1 : " + remainLeave);
            const updateProfile = {
                remainPersonalLeave: remainLeave,
                usePersonalLeaveTotal: usePersonalleave
            }

            ApiServices().updateSyncLeave(uid, updateProfile);

        }else{
            const updateProfile = {
                remainSickLeave: remainSickLeave,
            }
            ApiServices().updateSyncLeave(uid, updateProfile);
        }
        setUseRemainLastPsnLeave(useRemainLastYear)
        setUseSickLeave(sickleave);
        setRemainSickLeave(remainSickLeave);
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const dataRes = docSnap.data() as ProfileModel;
            setIsLoaded(true);
            setProfileRes(dataRes);
            console.log(dataRes);
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }


    const clickLeaveDetail = () => {
        setShowLeaveDetail(true);
    }

    const clickSimilarPersonality = () => {

        setShowSimilarPersonality(true);
    }


    const getMeLeaveHistory = async (uid: any) => {
        const q = query(collection(db, "leave"), where("dateleavefrom", "<", (startOfToday)),
            where("useruid", "==", uid),
            where("dateleavefrom", ">=", new Date("01/01/" + config.currentYear)),
            orderBy("dateleavefrom", "desc"));
        const querySnapshot = await getDocs(q);
        const leaveme: LeaveModel[] = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const dataRes = doc.data() as LeaveModel;
            dataRes.docId = doc.id;
            leaveme.push(dataRes);

        });
        setMeLeaveHistory(leaveme);
        setMeLeaveHistoryPagination(leaveme.slice(startAt, endAt))

    }
    const checkLeavePassDate = (leaveDate: Date) => {
        const today = new Date();
        return leaveDate < today;
    }

    const getPersonality = async (answer_id: string) => {
        //JDHHDgxYZ8UbYmRzbTZb
        ApiServices().postAnswerID(answer_id).then((res: any) => {
            setPersonality(res.results.mbti_results.type);
            setPersonalitResult(res.results.mbti_results);
            setPersonalityGraph(res.results.text_results);

            const resultObj = res;
            let datas = {
                labels: ["O", "C", "E", "A", "N"],
                datasets: [
                    {
                        data: [resultObj.results.text_results[0].big_5_user_score,
                        resultObj.results.text_results[1].big_5_user_score,
                        resultObj.results.text_results[2].big_5_user_score,
                        resultObj.results.text_results[3].big_5_user_score,
                        resultObj.results.text_results[4].big_5_user_score],
                        element: {
                            line: {
                                backgroundColor: "#000",
                                borderWidth: 5
                            },
                        },
                        borderColor: "#830000",
                        backgroundColor: "#fff",
                        borderWidth: 2,
                    }
                ],

            }
            if (datas) {
                setData(datas);
            }
        })
    }



    const getMeLeave = async (uid: any) => {
        const q = query(collection(db, "leave"), where("dateleavefrom", ">=", (startOfToday)), where("useruid", "==", uid));
        const querySnapshot = await getDocs(q);
        const leaveme: LeaveModel[] = [];
        const leavemeCancel: LeaveModel[] = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const dataRes = doc.data() as LeaveModel;
            dataRes.docId = doc.id;
            if (dataRes.statusleave !== "Canceled") {
                leaveme.push(dataRes);
            } else {
                leavemeCancel.push(dataRes);
            }


        });

        setMeLeave(leaveme);
        setMeLeaveCancel(leavemeCancel)
        setMeLeaveCancelPagination(leavemeCancel.slice(startAtCancel, endAtCancel))
        setMeLeavePagination(leaveme.slice(startAtUpcoming, endAtUpcoming))
    }


    const nextLeave = async (isUpcoming: boolean, isCancel: boolean) => {

        if (isUpcoming && !isCancel) {
            if (meLeave) {
                let startAtResult = 0;
                let endAtResult = 0;
                if ((endAtUpcoming + PAGE_LIMIT) > meLeave.length - 1) {
                    setEndAtUpcoming(meLeave.length);
                    endAtResult = meLeave.length;
                } else {
                    setEndAtUpcoming((endAtUpcoming + (PAGE_LIMIT)));
                    endAtResult = endAtUpcoming + (PAGE_LIMIT);
                }
                startAtResult = endAtUpcoming;
                setStartAtUpcoming(endAtUpcoming)
                setMeLeavePagination(meLeave.slice(startAtResult, endAtResult))
            }
        } else if (isCancel && !isUpcoming) {
            if (meLeaveCancel) {
                let startAtResult = 0;
                let endAtResult = 0;
                if ((endAtCancel + PAGE_LIMIT) > meLeaveCancel.length - 1) {
                    setEndAtCancel(meLeaveCancel.length);
                    endAtResult = meLeaveCancel.length;
                } else {
                    setEndAtCancel((endAtCancel + (PAGE_LIMIT)));
                    endAtResult = endAtCancel + (PAGE_LIMIT);
                }
                startAtResult = endAtCancel;
                setStartAtCancel(endAtUpcoming)
                setMeLeaveCancelPagination(meLeaveCancel.slice(startAtResult, endAtResult))
            }
        }


        else {
            if (meLeaveHistory) {

                let startAtResult = 0;
                let endAtResult = 0;
                if ((endAt + PAGE_LIMIT) > meLeaveHistory.length - 1) {
                    setEndAt(meLeaveHistory.length - 1);
                    endAtResult = meLeaveHistory.length;
                } else {
                    setEndAt((endAt + (PAGE_LIMIT)));
                    endAtResult = endAt + (PAGE_LIMIT);
                }
                startAtResult = endAt;
                setStartAt(endAt)
                setMeLeaveHistoryPagination(meLeaveHistory.slice(startAtResult, endAtResult))
            }
        }

    }
    const prevLeave = async (isUpcoming: boolean, isCancel: boolean) => {

        if (isUpcoming && !isCancel) {
            if (meLeave) {
                let startAtResult = 0;
                let endAtResult = 0;
                endAtResult = startAtUpcoming;
                setEndAtUpcoming(startAtUpcoming);

                if (meLeave.length >= PAGE_LIMIT) {
                    if ((startAtUpcoming - PAGE_LIMIT) < PAGE_LIMIT) {
                        setStartAtUpcoming(0);
                        startAtResult = 0;
                    } else {
                        setStartAtUpcoming((startAtUpcoming - PAGE_LIMIT));
                        startAtResult = startAtUpcoming - PAGE_LIMIT;
                    }
                    setMeLeavePagination(meLeave.slice(startAtResult, endAtResult))
                } else {
                    startAtResult = 0;
                    setMeLeavePagination(meLeave.slice(startAtResult, endAtResult))
                }

            }

        } else if (isCancel && !isUpcoming) {
            if (meLeaveCancel) {
                let startAtResult = 0;
                let endAtResult = 0;
                endAtResult = startAtCancel;
                setEndAtCancel(startAtCancel);

                if (meLeaveCancel.length >= PAGE_LIMIT) {
                    if ((startAtCancel - PAGE_LIMIT) < PAGE_LIMIT) {
                        setStartAtCancel(0);
                        startAtResult = 0;
                    } else {
                        setStartAtCancel((startAtCancel - PAGE_LIMIT));
                        startAtResult = startAtCancel - PAGE_LIMIT;
                    }
                    setMeLeaveCancelPagination(meLeaveCancel.slice(startAtResult, endAtResult))
                } else {
                    startAtResult = 0;
                    setMeLeaveCancelPagination(meLeaveCancel.slice(startAtResult, endAtResult))
                }

            }

        }

        else {
            if (meLeaveHistory) {
                let startAtResult = 0;
                let endAtResult = 0;
                endAtResult = startAt;
                setEndAt(startAt);

                if (meLeaveHistory.length >= PAGE_LIMIT) {
                    if ((startAt - PAGE_LIMIT) < PAGE_LIMIT) {
                        setStartAt(0);
                        startAtResult = 0;
                    } else {
                        setStartAt((startAt - PAGE_LIMIT));
                        startAtResult = startAt - PAGE_LIMIT;
                    }
                    setMeLeaveHistoryPagination(meLeaveHistory.slice(startAtResult, endAtResult))
                } else {
                    startAtResult = 0;
                    setMeLeaveHistoryPagination(meLeaveHistory.slice(startAtResult, endAtResult))
                }

            }

        }



    }




    const expandColumn = (index: number, isUpcoming: boolean) => {

        if (isUpcoming) {
            if (meLeavePagination) {
                if (meLeavePagination[index].isOpen) {
                    const arr = [...meLeavePagination];
                    arr[index].isOpen = false;
                    setMeLeavePagination(arr);
                } else {
                    const arr = [...meLeavePagination];
                    arr[index].isOpen = true;
                    setMeLeavePagination(arr);
                }
            }
        } else {
            if (meLeaveHistoryPagination) {
                if (meLeaveHistoryPagination[index].isOpen) {
                    const arr = [...meLeaveHistoryPagination];
                    arr[index].isOpen = false;
                    setMeLeaveHistoryPagination(arr);
                } else {
                    const arr = [...meLeaveHistoryPagination];
                    arr[index].isOpen = true;
                    setMeLeaveHistoryPagination(arr);
                }
            }
        }

    }

    const cancelLeave = async (leaveType: any, eventId: any, leaveId: any, leaveTime: any) => {

        let calendarID = "";
        if (leaveType === "Personal Leave") {
            if (leaveTime === "Full Day") {
                calendarID = config.calendarEmployeeleaveId
            } else {
                calendarID = config.calendarHalfleaveId
            }
        } else {
            calendarID = config.calendarSickleaveId
        }
        const auth = getAuth();
        if (auth) {
            if (eventId == "null") {
                ApiServices().deleteLeave(leaveId).then((res: any) => {
                    getMeLeave(uid);
                })
            } else {
                try {
                    await fetch(config.api.google_apis + calendarID + "/events/" + eventId, {
                        method: "DELETE",
                        headers: {
                            'Authorization': 'Bearer ' + user.accessToken
                        }
                    }).then((data) => {
                        ApiServices().deleteLeave(leaveId).then((res: any) => {
                            getMeLeave(uid);
                        })
                    })
                } catch {
                    signOut(auth);
                }

            }

        }



    };

    const clickTakeQuiz = () => {
        if (profileRes) {
            window.open(config.api.take_quiz + "&uuid=" + profileRes.uid, '_self');
        }

    }
    const UpdatePersonality = () => {
        if (updateMBTI) {
            const objectOcean = {
                mbti_id: answerID,
                mbti: updateMBTI.mbti_results.type,
                mbti_ocean: updateMBTI.text_results
            }
            ApiServices().updateOCEAN(uid, objectOcean).then((res: any) => {
                setSearchParams('');

                getPersonality(answerID);
                handleCloseConfirmMbti();
            })
        }
    }

    const closeModalConfirmMbti = () => {
        handleCloseConfirmMbti();
        setSearchParams('');
    }
    const getAllProfile = async () => {
        const q = query(collection(db, "users"),
            where("employeeStatus", "==", "Probation Approved"),
            orderBy("probationDate", "desc")
        );
        const querySnapshot = await getDocs(q);
        const allEmp: ProfileModel[] = [];
        querySnapshot.forEach((doc) => {
            const dataRes = doc.data() as ProfileModel;
            allEmp.push(dataRes);
        });

        setListEmp(allEmp);
        getSimilarPersonality(allEmp);

    }
    const getSimilarPersonality = (allEmp) => {

        ApiServices().getSimilarPersonality(profile.uid).then((res: any) => {
            const allSimilar: ProfileModel[] = [];
            const similarModel: SimilarPersonalityModel[] = res;


            similarModel.map((item: SimilarPersonalityModel, index: number) => {
                const mProfile = allEmp.filter((items: ProfileModel) => items.uid === item.uid);
                mProfile[0].rankingMBTI = item.similarity_percent;
                allSimilar.push(mProfile[0]);
            })

            setSimilarMBTI(allSimilar);
        })
    }

    const LogOut = () => {

        signOut(auth)
        navigate('/login');
    }
 
    return (
        <>
            {
                profileRes &&

                <div className="d-flex flex-column profile">

                    <div className="settings-control d-flex">
                        <AnnouncementComponent></AnnouncementComponent>
                        <img src={LOGOUT_IMG} className="settings-img" onClick={LogOut}></img>
                    </div>
                    <div className="profile-content-control d-flex flex-column">
                        <div className="header-profile" >


                            <div className="name-control" onClick={clickLeaveDetail}>
                                {
                                    profileRes.photoURL !== undefined ?
                                        <img src={profileRes.photoURL} className="profile-img my-auto" referrerPolicy="no-referrer"></img> :

                                        <img src={PROFILE_IMG} className="profile-img my-auto"></img>
                                }

                                <span className="name-text">{profileRes.displayName}</span>
                                <span className="position-text d-flex">Team:

                                    <span className="position-text-desc"> {profileRes.team}</span>

                                </span>
                                <span className="position-text d-flex">Role:

                                    <span className="position-text-desc"> {
                                        profileRes.position.map((item: string, index: number) => {
                                            return (
                                                index === (profileRes.position.length - 1) ?

                                                    item : item + ", "
                                            )
                                        })
                                    }</span>

                                </span>
                            </div>

                            {
                                (personalityGraph && personalityResult) &&
                                    personalityGraph.length > 0 ?
                                    <div className="graph-bg">
                                        {data ?
                                            <>
                                                <div className='container d-flex flex-column'>


                                                    <span className="personality-text-type d-flex">You're

                                                        <span className="personality-text-type-desc"> {personalityResult?.type}</span>

                                                    </span>
                                                    <span className="link-personality" onClick={clickTakeQuiz}>Change type</span>
                                                    <div className="ocean-control">
                                                        <div className="graph-control mx-auto">

                                                            <Radar data={data} options={options} className="graph-img mx-auto" onClick={clickGraphMBTI} />



                                                        </div>


                                                    </div>
                                                    <span className="link-personality" onClick={clickSimilarPersonality} >Similar with you</span>
                                                    <div className="d-flex mx-auto similar-profile-control" onClick={clickSimilarPersonality}>
                                                        {
                                                            similarMBTI &&
                                                            similarMBTI.slice(0, 3).map((item: ProfileModel, index: number) => {


                                                                return (
                                                                    <img src={item.photoURL} key={index} className="similar-profile-img" title={item.employeenickname} referrerPolicy="no-referrer"></img>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                            </> : <span className="link-personality" onClick={clickTakeQuiz}>Add Personality type</span>
                                        }
                                    </div>
                                    : <span className="link-personality my-auto" onClick={clickTakeQuiz}>Add Personality type</span>

                            }

                        </div>
                        <div className="divider-line"></div>

                        <div className="profile-items-control">
                            <div className="d-flex flex-column profile-item" onClick={clickLeaveDetail}>

                                <span className="profile-text-title">Vacations (used)</span>

                                <div className="d-flex">

                                    <span className="profile-text-hl">{
                                        (profileRes.usePersonalLeaveTotal)

                                    } <span className="profile-text-desc">/ {profileRes.totalPersonalLeave + profileRes.lastYearRemainDay}</span></span>
                                </div>

                            </div>
                            {
                                remainSickLeave && 
                                <div className="d-flex flex-column profile-item" onClick={clickLeaveDetail}>

                                <span className="profile-text-title">Sick (used)</span>

                                <div className="d-flex">

                                    <span className="profile-text-hl">{
                                        (profileRes.totalSickLeave - remainSickLeave)

                                    } <span className="profile-text-desc">/ {profileRes.totalSickLeave}</span></span>
                                </div>

                            </div>
                            }
                           

                            <div className="d-flex flex-column profile-item" onClick={clickAllHolidays}>

                                <span className="profile-text-title">Holidays</span>

                                <div className="d-flex">

                                    <span className="profile-text-hl">{
                                        allHolidays

                                    }</span>
                                </div>

                            </div>
                        </div>
                        <div className="content-profile">

                            
                            <div className="button-request-leave d-flex text-center" onClick={() => clickRequestLeave(false)}>
                                <div className="d-flex mx-auto">
                                    <img src={CALENDAR_IMG} className="img-calendar"></img>
                                    <span className="text-request-leave my-auto">ขอลา ที่นี่ ...</span></div>
                            </div>
                            <div className="button-request-leave d-flex text-center" onClick={() => clickRequestLeave(true)}>
                                <div className="d-flex mx-auto">
                                    <img src={CALENDAR_IMG} className="img-calendar"></img>
                                    <span className="text-request-leave my-auto">ขอลา 2025 ที่นี่ ...</span></div>
                            </div>

                            {
                                profileRes.role === "SuperAdmin" && <div className="button-admin-dashboard d-flex text-center" onClick={clickViewAdmin}>
                                    <div className="d-flex mx-auto">
                                        <img src={ADMIN_IMG} className="img-calendar"></img>
                                        <span className="text-request-leave my-auto">View Admin Dashboard</span></div>
                                </div>
                            }





                        </div>
                        <div className="divider-line"></div>
                        <Tabs
                            activeKey={key}
                            onSelect={(k: any) => setKey(k)}
                            className="tabs-control"
                            fill
                        >

                            <Tab eventKey="upcoming" title={`Upcoming`}>
                                <div className="leave-request d-flex flex-column">


                                    <div className="content-control">

                                        {
                                            meLeavePagination && meLeavePagination.length > 0 ?
                                                meLeavePagination?.map((item: LeaveModel, index: number) => {

                                                    return (
                                                        <div className="info-row-control d-flex flex-column" key={index} onClick={() => expandColumn(index, true)}>
                                                            <div className="info-row">
                                                                {
                                                                    item.leavetype === "Personal Leave" ?
                                                                        <div className="icon-rouder mx-auto">
                                                                            <span className="material-symbols-outlined  my-auto mx-auto icon-color">
                                                                                hiking
                                                                            </span>
                                                                        </div> :
                                                                        <div className="icon-rouder mx-auto">
                                                                            <span className="material-symbols-outlined my-auto mx-auto icon-color">
                                                                                medication
                                                                            </span>
                                                                        </div>
                                                                }
                                                               

                                                                <div className="info-text-control">
                                                                {
                                                                    item.isLock && <span className="profile-lock-leave">ไม่คิดวันลาในปี {config.currentYear}</span>
                                                                }
                                                                    {
                                                                        item.statusleave === configLanguage.approve ?

                                                                            <span className="text-info-status text-info-green">{configLanguage.approve}</span> :
                                                                            item.statusleave === configLanguage.awaiting ?
                                                                                <span className="text-info-status text-info-orange">{configLanguage.awaiting}</span> :
                                                                                item.statusleave === configLanguage.rejected ?
                                                                                    <span className="text-info-status text-info-red">{configLanguage.rejected}</span> :
                                                                                    <span className="text-info-status text-info-gray">{configLanguage.canceled}</span>
                                                                    }
                                                                    {
                                                                        item.leavetype === "Personal Leave" ?
                                                                            <span className="text-info-leave-header">{configLanguage.personal_leave}</span> :
                                                                            <span className="text-info-leave-header">{configLanguage.sick_leave}</span>
                                                                    }
                                                                    {
                                                                        item.dateleavefrom.toDate().toDateString() === item.dateleaveto.toDate().toDateString() ?

                                                                            <Moment format="ddd DD MMM YYYY" className="text-info-leave my-auto">
                                                                                {item.dateleavefrom.toDate()}
                                                                            </Moment> :
                                                                            <div className="d-flex text-info-leave">
                                                                                <Moment format="ddd DD MMM YYYY" >
                                                                                    {item.dateleavefrom.toDate()}
                                                                                </Moment> <span className="text-info-to">to</span> <Moment format="ddd DD MMM YYYY" >
                                                                                    {item.dateleaveto.toDate()}
                                                                                </Moment>
                                                                            </div>
                                                                    }

                                                                </div>
                                                                {
                                                                    item.isOpen ?
                                                                        <img src={COLLAPSE} className="expand-img mx-auto my-auto"></img> :
                                                                        <img src={EXPAND_IMG} className="expand-img mx-auto my-auto"></img>
                                                                }
                                                            </div>
                                                            {
                                                                item.isOpen ?
                                                                    <div className="expand-control d-flex flex-column">
                                                                        <span className="desc-text">Detail</span>
                                                                        <ul>
                                                                            <li className="desc-li-header">Total leave :
                                                                                <span className="desc-li-desc">
                                                                                    {item.leavedaytotal} Day(s)
                                                                                </span>
                                                                            </li>
                                                                            <li className="desc-li-header">Leave time :
                                                                                <span className="desc-li-desc">
                                                                                    {item.leavetime}
                                                                                </span>
                                                                            </li>
                                                                            <li className="desc-li-header">Reason :
                                                                                <span className="desc-li-desc">
                                                                                    {item.des}
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                        {
                                                                            !checkLeavePassDate(new Date(item.dateleavefrom.toDate())) && item.statusleave !== "Canceled" ?
                                                                                <button className="cancel-button"
                                                                                    onClick={() =>
                                                                                        cancelLeave(item.leavetype, item.eventId, item.docId, item.leavetime)}>Cancel Leave</button> : null
                                                                        }
                                                                    </div> : null

                                                            }

                                                        </div>
                                                    )
                                                }) : <div className="">No leave schedule.</div>
                                        }
                                    </div>



                                    <div className="d-flex text-center mx-auto">

                                        {
                                            startAtUpcoming > 0 && <span className="next-button my-auto" onClick={() => prevLeave(true, false)}>Previous</span>
                                        }

                                        {meLeave && meLeave.length > 0 &&
                                            meLeave && ((startAtUpcoming + PAGE_LIMIT) <= meLeave?.length - 1) ?
                                            <span className="next-button my-auto" onClick={() => nextLeave(true, false)}>Next</span> : null
                                        }


                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="history" title={`History`}>
                                <div className="leave-request d-flex flex-column">
                                    <div className="content-control">
                                        {
                                            meLeaveHistoryPagination && meLeaveHistoryPagination?.length > 0 ?
                                                meLeaveHistoryPagination?.map((item: LeaveModel, index: number) => {

                                                    return (
                                                        <div className="info-row-control d-flex flex-column" key={index} onClick={() => expandColumn(index, false)}>
                                                            <div className="info-row">

                                                                {
                                                                    item.leavetype === "Personal Leave" ?
                                                                        <div className="icon-rouder mx-auto">
                                                                            <span className="material-symbols-outlined  my-auto mx-auto icon-color">
                                                                                hiking
                                                                            </span>
                                                                        </div> :
                                                                        <div className="icon-rouder mx-auto">
                                                                            <span className="material-symbols-outlined my-auto mx-auto icon-color">
                                                                                medication
                                                                            </span>
                                                                        </div>
                                                                }

                                                                <div className="info-text-control">
                                                                    {
                                                                        item.leavetype === "Personal Leave" ?
                                                                            <span className="text-info-leave-header">{configLanguage.personal_leave}</span> :
                                                                            <span className="text-info-leave-header">{configLanguage.sick_leave}</span>
                                                                    }

                                                                    {
                                                                        item.dateleavefrom.toDate().toDateString() === item.dateleaveto.toDate().toDateString() ?

                                                                            <Moment format="ddd DD MMM YYYY" className="text-info-leave my-auto">
                                                                                {item.dateleavefrom.toDate()}
                                                                            </Moment> :
                                                                            <div className="d-flex text-info-leave">
                                                                                <Moment format="ddd DD MMM YYYY" >
                                                                                    {item.dateleavefrom.toDate()}
                                                                                </Moment> <span className="text-info-to">to</span> <Moment format="ddd DD MMM YYYY" >
                                                                                    {item.dateleaveto.toDate()}
                                                                                </Moment>
                                                                            </div>
                                                                    }

                                                                </div>
                                                                {
                                                                    item.isOpen ?
                                                                        <img src={COLLAPSE} className="expand-img mx-auto my-auto"></img> :
                                                                        <img src={EXPAND_IMG} className="expand-img mx-auto my-auto"></img>
                                                                }


                                                                {/* <span className="text-info-leave my-auto">{item.dateleavefrom.toDate().toLocaleDateString()}</span>
                                                <span className="text-info-leave my-auto">

                                                    {

                                                        item.statusleave === "Approved" ?

                                                            <span className="text-info-green">{item.statusleave}</span> :
                                                            item.statusleave === "Awaiting" ?
                                                                <span className="text-info-orange">{item.statusleave}</span> :
                                                                item.statusleave === "Rejected" ?
                                                                    <span className="text-info-red">{item.statusleave}</span> :
                                                                    <span className="text-info-gray">{item.statusleave}</span>


                                                    }</span>
                                               */}
                                                            </div>
                                                            {
                                                                item.isOpen ?
                                                                    <div className="expand-control d-flex flex-column">
                                                                        <span className="desc-text">Details</span>
                                                                        <ul>

                                                                            <li className="desc-li-header">Total leave :
                                                                                <span className="desc-li-desc">
                                                                                    {item.leavedaytotal} Day(s)
                                                                                </span>
                                                                            </li>
                                                                            <li className="desc-li-header">Leave time :
                                                                                <span className="desc-li-desc">
                                                                                    {item.leavetime}
                                                                                </span>
                                                                            </li>
                                                                            <li className="desc-li-header">Reason :
                                                                                <span className="desc-li-desc">
                                                                                    {item.des}
                                                                                </span>
                                                                            </li>
                                                                        </ul>

                                                                    </div> : null

                                                            }

                                                        </div>
                                                    )
                                                }) : <div className="">No leave schedule.</div>
                                        }
                                    </div>

                                    <div className="d-flex text-center mx-auto">
                                        {
                                            startAt > 0 && <span className="next-button my-auto" onClick={() => prevLeave(false, false)}>Previous</span>
                                        }

                                        {meLeaveHistory && meLeaveHistory?.length > 0 &&
                                            meLeaveHistory && (endAt != meLeaveHistory?.length - 1) ?
                                            <span className="next-button my-auto" onClick={() => nextLeave(false, false)}>Next</span> : null
                                        }


                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="cancelled" title={`Cancelled`}>
                                <div className="leave-request d-flex flex-column">


                                    <div className="content-control">

                                        {
                                            meLeaveCancelPagination && meLeaveCancelPagination.length > 0 ?
                                                meLeaveCancelPagination?.map((item: LeaveModel, index: number) => {

                                                    return (
                                                        <div className="info-row-control d-flex flex-column" key={index} onClick={() => expandColumn(index, true)}>
                                                            <div className="info-row">
                                                                {
                                                                    item.leavetype === "Personal Leave" ?
                                                                        <div className="icon-rouder mx-auto">
                                                                            <span className="material-symbols-outlined  my-auto mx-auto icon-color">
                                                                                hiking
                                                                            </span>
                                                                        </div> :
                                                                        <div className="icon-rouder mx-auto">
                                                                            <span className="material-symbols-outlined my-auto mx-auto icon-color">
                                                                                medication
                                                                            </span>
                                                                        </div>
                                                                }

                                                                <div className="info-text-control">
                                                                    {
                                                                        item.statusleave === configLanguage.approve ?

                                                                            <span className="text-info-status text-info-green">{configLanguage.approve}</span> :
                                                                            item.statusleave === configLanguage.awaiting ?
                                                                                <span className="text-info-status text-info-orange">{configLanguage.awaiting}</span> :
                                                                                item.statusleave === configLanguage.rejected ?
                                                                                    <span className="text-info-status text-info-red">{configLanguage.rejected}</span> :
                                                                                    <span className="text-info-status text-info-gray">{configLanguage.canceled}</span>
                                                                    }
                                                                    {
                                                                        item.leavetype === "Personal Leave" ?
                                                                            <span className="text-info-leave-header">{configLanguage.personal_leave}</span> :
                                                                            <span className="text-info-leave-header">{configLanguage.sick_leave}</span>
                                                                    }
                                                                    {
                                                                        item.dateleavefrom.toDate().toDateString() === item.dateleaveto.toDate().toDateString() ?

                                                                            <Moment format="ddd DD MMM YYYY" className="text-info-leave my-auto">
                                                                                {item.dateleavefrom.toDate()}
                                                                            </Moment> :
                                                                            <div className="d-flex text-info-leave">
                                                                                <Moment format="ddd DD MMM YYYY" >
                                                                                    {item.dateleavefrom.toDate()}
                                                                                </Moment> <span className="text-info-to">to</span> <Moment format="ddd DD MMM YYYY" >
                                                                                    {item.dateleaveto.toDate()}
                                                                                </Moment>
                                                                            </div>
                                                                    }

                                                                </div>
                                                                {
                                                                    item.isOpen ?
                                                                        <img src={COLLAPSE} className="expand-img mx-auto my-auto"></img> :
                                                                        <img src={EXPAND_IMG} className="expand-img mx-auto my-auto"></img>
                                                                }
                                                            </div>
                                                            {
                                                                item.isOpen ?
                                                                    <div className="expand-control d-flex flex-column">
                                                                        <span className="desc-text">Detail</span>
                                                                        <ul>
                                                                            <li className="desc-li-header">Total leave :
                                                                                <span className="desc-li-desc">
                                                                                    {item.leavedaytotal} Day(s)
                                                                                </span>
                                                                            </li>
                                                                            <li className="desc-li-header">Leave time :
                                                                                <span className="desc-li-desc">
                                                                                    {item.leavetime}
                                                                                </span>
                                                                            </li>
                                                                            <li className="desc-li-header">Reason :
                                                                                <span className="desc-li-desc">
                                                                                    {item.des}
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                        {
                                                                            !checkLeavePassDate(new Date(item.dateleavefrom.toDate())) && item.statusleave !== "Canceled" ?
                                                                                <button className="cancel-button"
                                                                                    onClick={() =>
                                                                                        cancelLeave(item.leavetype, item.eventId, item.docId, item.leavetime)}>Cancel Leave</button> : null
                                                                        }
                                                                    </div> : null

                                                            }

                                                        </div>
                                                    )
                                                }) : <div className="">No leave schedule.</div>
                                        }
                                    </div>



                                    <div className="d-flex text-center mx-auto">

                                        {
                                            startAtCancel > 0 && <span className="next-button my-auto" onClick={() => prevLeave(false, true)}>Previous</span>
                                        }

                                        {meLeaveCancel && meLeaveCancel.length > 0 &&
                                            meLeaveCancel && ((startAtCancel + PAGE_LIMIT) <= meLeaveCancel?.length - 1) ?
                                            <span className="next-button my-auto" onClick={() => nextLeave(false, true)}>Next</span> : null
                                        }


                                    </div>

                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <DashboardComponent ></DashboardComponent>
                </div>
            }

            {
                showLeaveDetail ?
                    <Modal
                        show={showLeaveDetail}
                        onHide={handleCloseLeaveDetail}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EmployeesDetailComponent dbAccess={db} uid={uid} ></EmployeesDetailComponent>
                        </Modal.Body>

                    </Modal> :
                    showConfirmMbti && profileRes && updateMBTI ?

                        <Modal
                            show={showConfirmMbti}
                            onHide={handleCloseConfirmMbti}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Personality Type</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="d-flex flex-column confirm-mbti ">
                                    <span className="confirm-mbti-message">Are you sure for replace `{profileRes.mbti}` with `{updateMBTI.mbti_results.type}`?</span>

                                    <div className="confirm-mbti-grid-button">
                                        <button className="confirm-mbti-confirm" onClick={UpdatePersonality}>Replace</button>
                                        <button className="confirm-mbti-cancel" onClick={closeModalConfirmMbti}>Cancel</button>
                                    </div>
                                </div>



                            </Modal.Body>

                        </Modal>
                        :
                        showSimilarPersonality && similarMBTI ?
                            <SimilarPersonalityModal showSimilarPersonality={showSimilarPersonality} similarList={similarMBTI} handleCloseSimilarPersonality={handleCloseSimilarPersonality}></SimilarPersonalityModal>
                            :
                            showAllHoliday ?
                                <Modal
                                    show={showAllHoliday}
                                    onHide={handleCloseAllHolidays}
                                    backdrop="static"
                                    keyboard={false}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <HolidaysComponent profileRes={profileRes} ></HolidaysComponent>
                                    </Modal.Body>

                                </Modal> :

                                showMBTIDetail ?
                                    <Modal
                                        show={showMBTIDetail}
                                        onHide={handleCloseMBTIDetail}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <MBTIDetailComponent personalityGraphs={personalityGraph} ></MBTIDetailComponent>
                                        </Modal.Body>

                                    </Modal> :
                                    show ?
                                        <Modal
                                            show={show}
                                            onHide={handleClose}
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {
                                                    profileRes && <RequestLeaveComponent setValue={setValue} other={profileRes} handleClose={handleClose} isNextYear={isNextYear}></RequestLeaveComponent>
                                                }
                                            </Modal.Body>

                                        </Modal> : null
            }


        </>
    )
}
