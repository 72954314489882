import { addDoc, collection, doc, getDoc, getDocs, getFirestore, query, Timestamp, where } from "firebase/firestore";
import React, { useState } from "react";
import config from "../../../../config";
import configLanguage from "../../../../config-language";
import LeaveModel from "../../../../model/leave.model";
import ProfileModel from "../../../../model/profile.model";
import ApiServices from "../../../../services/api.service";
import EDIT_IMG from "../../../../assets/images/edit.png";
import "./employees-detail.scss";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { default as ReactSelect } from "react-select";
import { initializeApp } from "firebase/app";
import PositionModel from "../../../../model/position.model";
import Select from 'react-select';
import { components } from "react-select";
import { useNavigate } from "react-router-dom";
const EmployeesDetailComponent = ({ dbAccess, uid }: any) => {
    let navigate = useNavigate();
    const db: any = dbAccess;
    const id = uid;
    const [profileRes, setProfileRes] = useState<ProfileModel>();
    const [usePsnLeave, setUsePsnLeave] = useState(0);
    const [useRemainLastPsnLeave, setUseRemainLastPsnLeave] = useState(0);
    const [remainPsnLeave, setRemainPsnLeave] = useState(0);
    const [useSickLeave, setUseSickLeave] = useState(0);
    const [useOnlyDefaultLeave, setUseOnlyDefaultLeave] = useState(0);
    const [remainSickLeave, setRemainSickLeave] = useState(0);
    const [isEditMode, setEditMode] = useState(false);
    const [team, setTeam] = useState("");
    const [dob, setDob] = useState<Date | null>(new Date());
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [position, setPosition] = useState<PositionModel[]>();
    const [selectedOption, setSelectedOption] = useState<PositionModel[]>();
    const [lockTotalDate, setLockTotalDate] = useState(0);
    const getProfileEmp = async () => {
        const q = query(collection(db, "users"), where("uid", "==", uid));

        const docRef = doc(db, "users", uid);
        let profile: ProfileModel;
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const profile = docSnap.data() as ProfileModel;

            if (profile.email) {
                setEmail(profile.email)
            }

            if (profile.phone) {
                setContact(profile.phone)
            }

            if (profile.position) {
                let pos: PositionModel[] = []
                profile.position.map((item: string, index: number) => {
                    const posMap: PositionModel = {
                        key: item,
                        value: item,
                        label: item
                    }
                    pos.push(posMap);
                })

                setSelectedOption(pos);
            }

            if (profile.birthDay) {
                setDob(new Date(profile.birthDay.toDate().toLocaleDateString()))
            }

            if (profile.team) {
                setTeam(profile.team)
            }
            setProfileRes(profile);
            checkLeave(id, profile);
            // checkLeaveNextYear(id, profile);
            // checkLeaveLastYear(id, profile);
            ApiServices().getPositions().then((res) => {
                const querySnapshot = res;
                let ptList: PositionModel[] = [];
                querySnapshot.forEach((doc) => {
                    const dataRes = doc.data() as PositionModel;
                    dataRes.label = dataRes.value;
                    ptList.push(dataRes);
                });
                setPosition(ptList)
            })



        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }
    const getProfileEmpExt = async (id) => {
        const q = query(collection(db, "users"), where("uid", "==", id));

        const docRef = doc(db, "users", id);
        let profile: ProfileModel;
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const profile = docSnap.data() as ProfileModel;
            checkLeave(id, profile);
            checkLeaveNextYear(id, profile);
            checkLeaveLastYear(id, profile);
            calculateNewYear1Time(id, profile.displayName)
            const reportLastYear = "report_" + config.lastYear;
            if (profile[reportLastYear].remain > 5) {
                const updateRemain = {
                    lastYearRemainDay: 5
                }
                console.log(profile[reportLastYear]);
                ApiServices().updateProfile(id, updateRemain)
            } else {
                const updateRemain = {
                    lastYearRemainDay: profile[reportLastYear].remain
                }
                console.log(profile[reportLastYear]);
                ApiServices().updateProfile(id, updateRemain)
            }




        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    const clickEditProfile = () => {
        setEditMode(true);
    }
    const checkLeaveNextYear = async (id: any, profile: ProfileModel) => {
        const q = query(collection(db, "leave"), where("dateleavefrom", ">=", new Date("01/01/" + config.nextYear)),
            where("dateleavefrom", "<=", new Date("12/31/" + (config.nextYear))),
            where("statusleave", "==", "Approved")
            , where("useruid", "==", id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            let lockDate = 0;
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            const dataRes = doc.data() as LeaveModel;
            dataRes.docId = doc.id;
            if (dataRes.isLock && dataRes.leaveNextYear) {
                lockDate += dataRes.leaveNextYear;
                console.log(dataRes.leavedaytotal);
            }
            console.log(lockDate);
            setLockTotalDate(lockDate);

        })
    }
    const checkLeaveLastYear = async (id: any, profile: ProfileModel) => {
        const q = query(collection(db, "leave"), where("dateleavefrom", ">=", new Date("01/01/" + config.lastYear)),
            where("dateleavefrom", "<=", new Date("12/31/" + (config.lastYear))),
            where("statusleave", "==", "Approved")
            , where("useruid", "==", id));
        const querySnapshot = await getDocs(q);
        let calDate = 0;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            const dataRes = doc.data() as LeaveModel;
            dataRes.docId = doc.id;

            if (dataRes.leavetype === configLanguage.personal_leave) {
                calDate += 1;
            }


        })
        console.log(calDate);
    }

    const checkLeave = async (id: any, profile: ProfileModel) => {
        const q = query(collection(db, "leave"), where("dateleavefrom", ">=", new Date("01/01/" + config.currentYear)),
            where("dateleavefrom", "<=", new Date("12/31/" + (config.currentYear))),
            where("statusleave", "==", "Approved")
            , where("useruid", "==", id));
        const querySnapshot = await getDocs(q);
        const leaveme: LeaveModel[] = [];
        let personalLeave = 0;
        let useRemainLastYear = 0;
        let isUseRemainLastYearFull = false;
        let overRemainTotal = 0;
        let usePersonalleave = 0;
        let useOnlyDFLeave = 0;
        let sickleave = 0;
        if (querySnapshot.size == 0) {
            personalLeave = profile.remainPersonalLeave;
            console.log(personalLeave)
            setUsePsnLeave(0);
            setRemainPsnLeave(personalLeave);
            setUseOnlyDefaultLeave(0)
            const updateProfile = {
                usePersonalLeaveTotal: 0,
                remainPersonalLeave: profile.totalPersonalLeave + profile.lastYearRemainDay
            }
            setRemainPsnLeave(profile.totalPersonalLeave + profile.lastYearRemainDay);
            ApiServices().updateSyncLeave(uid, updateProfile);
        } else {


            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const dataRes = doc.data() as LeaveModel;
                dataRes.docId = doc.id;
                if (dataRes.leavetype === configLanguage.personal_leave) {

                    if (new Date(dataRes.datecreate.toDate()) <= new Date("05/01/" + config.currentYear)) {

                        useRemainLastYear += dataRes.leavedaytotal;
                        if (useRemainLastYear >= profile.lastYearRemainDay) {
                            isUseRemainLastYearFull = true;
                            overRemainTotal = useRemainLastYear - profile.lastYearRemainDay;
                            useRemainLastYear = profile.lastYearRemainDay;
                        } else {
                            if (!isUseRemainLastYearFull) {
                            } else {
                                useOnlyDFLeave = useOnlyDFLeave + dataRes.leavedaytotal;

                            }
                        }

                        personalLeave = personalLeave + dataRes.leavedaytotal;
                    } else if (new Date(dataRes.datecreate.toDate()) > new Date("05/01/" + config.currentYear)) {
                        useOnlyDFLeave = useOnlyDFLeave + dataRes.leavedaytotal;
                        personalLeave = personalLeave + dataRes.leavedaytotal;

                    } else {

                    }

                    // if(isUseRemainLastYearFull){
                    //     usePersonalleave += dataRes.leavedaytotal;
                    // }
                    usePersonalleave += dataRes.leavedaytotal;

                } else {
                    sickleave += dataRes.leavedaytotal;

                }

                leaveme.push(dataRes);

            });



            const remainSickLeave = profile.totalSickLeave - sickleave;
            console.log("remainSickLeave: " + remainSickLeave);
            console.log("personalLeave : " + personalLeave)
            console.log("useRemainLastYear : " + useRemainLastYear)
            console.log("useOnlyDFLeave : " + useOnlyDFLeave)
            console.log("========")
            if (personalLeave > 0) {
                if (useRemainLastYear >= profile.lastYearRemainDay) {
                    const remainLeave = (profile.totalPersonalLeave + profile.lastYearRemainDay) - (personalLeave);

                    setUsePsnLeave(personalLeave - useRemainLastYear);
                    setRemainPsnLeave(remainLeave);
                    const updateProfile = {
                        remainPersonalLeave: remainLeave,
                        remainSickLeave: remainSickLeave,
                        usePersonalLeaveTotal: usePersonalleave
                    }
                    console.log(updateProfile);
                    ApiServices().updateSyncLeave(uid, updateProfile);
                } else {

                    if (new Date() >= new Date("05/01/" + config.currentYear)) {
                        const remainLeave = (profile.totalPersonalLeave + useRemainLastYear) - (useOnlyDFLeave + useRemainLastYear);
                        setUsePsnLeave(personalLeave - useRemainLastYear);
                        setRemainPsnLeave(remainLeave);
                        const updateProfile = {
                            remainPersonalLeave: remainLeave,
                            remainSickLeave: remainSickLeave,
                            usePersonalLeaveTotal: usePersonalleave
                        }
                        console.log(updateProfile);
                        ApiServices().updateSyncLeave(uid, updateProfile);
                    } else {
                        const remainLeave = (profile.totalPersonalLeave + profile.lastYearRemainDay) - (useOnlyDFLeave + useRemainLastYear);
                        setUsePsnLeave(personalLeave - useRemainLastYear);
                        setRemainPsnLeave(remainLeave);
                        const updateProfile = {
                            remainPersonalLeave: remainLeave,
                            remainSickLeave: remainSickLeave,
                            usePersonalLeaveTotal: usePersonalleave
                        }
                        console.log(updateProfile);
                        ApiServices().updateSyncLeave(uid, updateProfile);
                    }


                }
            } else {
                const updateProfile = {
                    remainSickLeave: remainSickLeave,
                }
                ApiServices().updateSyncLeave(uid, updateProfile);
                setRemainPsnLeave(profile.remainPersonalLeave);
            }
            setRemainSickLeave(remainSickLeave);
            setUseOnlyDefaultLeave(personalLeave - useRemainLastYear)
        }

        setUseRemainLastPsnLeave(useRemainLastYear)
        setUseSickLeave(sickleave);




        // leaveme.filter((val: LeaveModel) => val.leavetype === configLanguage.personal_leave)
        //     .map((item: LeaveModel, index: number) => {
        //         console.log(item.employeenickname);
        //     })
    }


    const cancelEditProfile = () => {
        setEditMode(false);

    }
    const onDobChange = (e: any) => {
        setDob(new Date(e.target.value));
    }

    const onEmailChange = (e: any) => {
        setEmail(e.target.value);
    }

    const onContactChange = (e: any) => {
        setContact(e.target.value);
    }
    const onTeamChange = (e: any) => {
        setTeam(e.target.value);
    }

    const calculateNewYear1Time = async (id, name) => {
        const q = query(collection(db, "leave"), where("dateleavefrom", ">=", new Date("01/01/" + config.currentYear)),
            where("dateleavefrom", "<=", new Date("12/31/" + (config.currentYear))),
            where("statusleave", "==", "Approved")
            , where("useruid", "==", id));
        const querySnapshot = await getDocs(q);
        const leaveme: LeaveModel[] = [];
        let personalLeave = 0;
        let useRemainLastYear = 0;
        let isUseRemainLastYearFull = false;
        let overRemainTotal = 0;
        let usePersonalleave = 0;
        let useOnlyDFLeave = 0;
        let sickleave = 0;
        if (querySnapshot.size == 0) {
            console.log(name + " (Leave 2024 Total: " + 0 + "day) ")
        } else {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const dataRes = doc.data() as LeaveModel;
                dataRes.docId = doc.id;
                if (dataRes.leavetype === configLanguage.personal_leave) {
                    usePersonalleave += dataRes.leavedaytotal;
                    console.log(name + " (Leave 2024 Personal leave Total: " + usePersonalleave + "day) ")
                } else {
                    sickleave += dataRes.leavedaytotal;
                    console.log(name + " (Leave 2024 Sick leave Total: " + sickleave + "day) ")
                }

                leaveme.push(dataRes);

            });




        }


    }

    const saveEditProfile = () => {
        let positions: string[] = [];
        if (selectedOption) {
            selectedOption.map((item: PositionModel, index: number) => {

                positions.push(item.key)
            })
        }
        if(dob){
            const objectEditProfile = {
                email: email,
                phone: contact,
                birthDay: Timestamp.fromDate(dob),
                team: team,
                position: positions
            }
            ApiServices().updateProfile(profileRes?.uid, objectEditProfile).then((res: any) => {
                setEditMode(false);
                getProfileEmp();
            })
        }
        
    }



    React.useEffect(() => {
        getProfileEmp();
        //  getProfileEmpExt("GPSzl6M0MwMXoX4gX3pgIzZNE5p2")
    }, [])


   

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <span className="text-black">{props.value}</span>
                </components.Option>
            </div>
        );
    };
    const handleChange = (selected) => {
        setSelectedOption(selected);
    };

    const clickFullReport = (id: any) => {
        navigate('/admin/report/' + id);
    }

    return (
        <>
            {
                profileRes &&
                <div className="employees-detail d-flex flex-column">
                    <h1 className="header-text">{profileRes?.employeenickname}
                        <span className="sub-header-text"> ({profileRes?.displayName})</span>
                    </h1>
                    <div className="divider-line"></div>
                    <div className={
                        isEditMode ? "edit-mode" : "profile-control d-flex flex-column"
                    }>

                        <div className="section-control">
                            <span className="sub-header-text">Profile</span>
                            {

                                !isEditMode && <button className="edit-button " onClick={clickEditProfile}>
                                    <img src={EDIT_IMG} className="edit-img my-auto"></img>
                                    Edit profile</button>
                            }

                        </div>
                        <div className="section-control">
                            <span className="section-header">
                                Team
                            </span>
                            {isEditMode ?
                                <Form.Select className="section-dropdown" onChange={(e) => onTeamChange(e)}
                                    value={team}
                                >

                                    {
                                        configLanguage.team.map((item: string, index: number) => {
                                            return (
                                                item === team ?
                                                    <option className="text-center" value={item} selected>{item}</option> :
                                                    <option className="text-center" value={item}>{item}</option>
                                            )
                                        })
                                    }

                                </Form.Select>

                                : <span className="section-desc">
                                    {profileRes.team ? profileRes.team : "-"}
                                </span>}

                        </div>
                        <div className="section-control">
                            <span className="section-header">
                                Position
                            </span>
                            {isEditMode && position && profileRes.role !== "SuperAdmin" ?
                                <span
                                    className="d-inline-block"
                                    data-toggle="popover"
                                    data-trigger="focus"
                                    data-content="Please selecet account(s)"
                                >
                                    {
                                        position ?
                                            <ReactSelect
                                                options={position}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={(s) => handleChange(s)}
                                                value={selectedOption}
                                            /> : null
                                    }
                                </span>
                                : <span className="section-desc text-right">
                                    {
                                        profileRes.position.map((item: any, index: number) => {


                                            return (
                                                <span key={index}>
                                                    {item}
                                                    {
                                                        (profileRes.position.length == index + 1) ?
                                                            "" : ", "
                                                    }
                                                </span>
                                            )
                                        })
                                    }
                                </span>}

                        </div>
                        <div className="section-control">

                            <span className="section-header">
                                Birthday
                            </span>
                            {isEditMode ?

                                <ReactDatePicker
                                    className="section-dropdown"
                                    selected={dob}
                                    onChange={(date) => setDob(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                /> :
                                <span className="section-desc">{profileRes.birthDay ? profileRes.birthDay.toDate().toDateString() : "-"}    </span>
                            }
                        </div>
                        <div className="section-control">
                            <span className="section-header">
                                Email
                            </span>
                            {isEditMode ? <Form.Control className="section-dropdown"
                                onChange={(e) => onEmailChange(e)}
                                defaultValue={email}
                                type="email" name='email' /> :
                                <span className="section-desc">{profileRes.email ? profileRes.email : "-"}    </span>
                            }
                        </div>
                        <div className="section-control">
                            <span className="section-header">
                                Contact
                            </span>
                            {isEditMode ? <Form.Control className="section-dropdown"
                                onChange={(e) => onContactChange(e)}
                                placeholder="xxx-xxx-xxxx"
                                defaultValue={contact}
                                type="tel" name='phone' /> :
                                <span className="section-desc">{profileRes.phone ? profileRes.phone : "-"}    </span>
                            }
                        </div>
                        {
                            isEditMode ?
                                <div className="section-control">
                                    <div className="d-flex ml-auto">
                                        <button className="save-button" onClick={saveEditProfile}>Save</button>
                                        <button className="cancel-button" onClick={cancelEditProfile}>Cancel</button>
                                    </div>


                                </div>
                                : null
                        }


                    </div>
                    <h1 className="header-text">Year {config.currentYear} {profileRes?.employeenickname} activities:</h1>
                    {
                        lockTotalDate > 0 &&
                        <>
                            <div className="divider-line"></div>
                            <div className="grid-display">
                                <span className="desc-header-text">Lock personal leave 2024 </span>
                                <span className="desc-text">{lockTotalDate}</span>
                            </div>
                        </>
                    }

                    <div className="divider-line"></div>
                    <span className="sub-header-text">{configLanguage.personal_leave}</span>
                    <div className="grid-display">
                        <span className="desc-header-text">This year remain ({config.currentYear})</span>
                        <span className="desc-text">{profileRes?.totalPersonalLeave}</span>
                    </div>
                    <div className="grid-display">
                        <span className="desc-header-text">Last year remain ({config.lastYear})</span>
                        <span className="desc-text">{profileRes?.lastYearRemainDay}</span>
                    </div>
                    <div className="grid-display">
                        <span className="desc-header-text">Used last year ({config.lastYear}) remain</span>
                        <span className="desc-text">{useRemainLastPsnLeave != 0 ? "-" + useRemainLastPsnLeave : useRemainLastPsnLeave}</span>
                    </div>
                    <div className="grid-display">
                        <span className="desc-header-text">Used this year ({config.currentYear}) remain</span>
                        <span className="desc-text">{useOnlyDefaultLeave != 0 ? "-" + useOnlyDefaultLeave : useOnlyDefaultLeave}</span>
                    </div>
                    <div className="grid-display">
                        <span className="desc-header-text">Total Remain</span>
                        <span className="desc-text">{remainPsnLeave}</span>
                    </div>
                    <div className="divider-line"></div>
                    <span className="sub-header-text">{configLanguage.sick_leave}</span>
                    <div className="grid-display">
                        <span className="desc-header-text">Total</span>
                        <span className="desc-text">{profileRes?.totalSickLeave}</span>
                    </div>
                    <div className="grid-display">
                        <span className="desc-header-text">Used</span>
                        <span className="desc-text">{useSickLeave}</span>
                    </div>
                    <div className="grid-display">
                        <span className="desc-header-text">Remain</span>
                        <span className="desc-text">{remainSickLeave}</span>
                    </div>

                    <button className="button-full-report" onClick={() => clickFullReport(profileRes.uid)}>See Full report</button>
                   
                </div>
            }
        </>
    )
}

export default EmployeesDetailComponent;