import './login.scss'
import { initializeApp } from "firebase/app";
import React, { useState } from 'react';
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, signInWithCredential, } from "firebase/auth";
import ApiCalendar from 'react-google-calendar-api';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    doc,
    updateDoc,
    Timestamp,
    Firestore,
} from "firebase/firestore";
import BTN_GMAIL_DARK_NORMAL from "../../assets/images/btn_google_signin_dark_normal_web@2x.png";
import config from '../../config';
import ErrorPopup from '../modal/error-popup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createGlobalState } from 'react-hooks-global-state';
import ApiServices from '../../services/api.service';
import LOGIN_COVER_IMG from '../../assets/images/login-cover.jpg';
import tokenService from '../../services/token.service';
import { ProgressBar, Spinner } from 'react-bootstrap';
const initialState = { ggAuth: {} };
export const { useGlobalState } = createGlobalState(initialState)
const LoginComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showError, setShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);

    let navigate = useNavigate();

    React.useEffect(() => {
        signOut(auth);
    }, [])


    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/calendar');
        const auth = getAuth(initializeApp(config.firebaseConfig));
        const result = await signInWithPopup(auth, provider)
        if (result) {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            tokenService.setUser(credential);
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const q = query(collection(db, "users"), where("userUID", "==", result.user.uid));
            console.log(result.user);
            setIsLoading(true);
            const docs = await getDocs(q);
            if (docs.docs.length === 0) {

                const newEmployer = {
                    displayName: result.user.displayName,
                    email: result.user.email,
                    photoURL: result.user.photoURL,
                    userUID: result.user.uid,
                    employeeStatus: "-",
                    employeenickname: result.user.displayName,
                    address: "",
                    birthDay: Timestamp.now() ,
                    lastLogin: Timestamp.now(),
                    lastYearRemainDay: 0,
                    mbti: "",
                    mbti_id: "",
                    phone: "-",
                    position: ["-"],
                    probationDate: Timestamp.now() ,
                    remainPersonalLeave: 0,
                    remainSickLeave: 30,
                    remainWfh: 0,
                    role: "Employee",
                    sex: "-",
                    startDate: Timestamp.now(),
                    team: "-",
                    totalPersonalLeave: 15,
                    totalSickLeave: 30,
                    usePersonalLeaveTotal: 0,

                }
                const docRef = await addDoc(collection(db, "users"), newEmployer);
                const washingtonRef = doc(db, "users", docRef.id);
                console.log(docRef.id);
                await updateDoc(washingtonRef, {
                    uid: docRef.id,
                    userUID: result.user.uid
                }).then((res: any) => {
                    handleCredentialResponse(credential);
                })
            } else {
                docs.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    const objectRes = {
                        displayName: result.user.displayName,
                        email: result.user.email,
                        photoURL: result.user.photoURL,
                        lastLogin: Timestamp.now()
                    }
                    ApiServices().updateProfile(doc.id, objectRes).then((res: any) => {
                        handleCredentialResponse(credential);

                    })
                });



            }
        }

    };
    function handleCredentialResponse(response: any) {
        // Build Firebase credential with the Google ID token.

        // Sign in with credential from the Google user.
        signInWithCredential(auth, response)
            .then(result => {

                if (searchParams.get("redirect_url") === "admin") {
                    navigate('/admin')
                } else {
                    navigate('/')
                }

            })
            .catch((error) => {
                setIsLoading(false);
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The credential that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }
    return (

        <>
            <div className='container d-flex flex-column login'>

                <img src={LOGIN_COVER_IMG} className='img-cover mx-auto'></img>
                <h1 className='text-header'>Welcome, Scoutian & Hooopster</h1>
                <h1 className='text-desc'>Software for manage all employees.</h1>

                {
                    isLoading ? <div className='d-flex flex-column login-loading-control'>
                        <Spinner animation="border" className='login-loading' />
                        <span className='login-loading-text' >Logging in...</span>
                    </div> : <img src={BTN_GMAIL_DARK_NORMAL} className="button-gmail" onClick={signInWithGoogle}></img>
                }



            </div>
            {
                showError && <ErrorPopup message={errMessage} isShow={true} ></ErrorPopup>
            }

        </>
    )
}

export default LoginComponent;